var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showAchWarning
    ? _c("fa-icon", {
        directives: [
          {
            name: "b-popover",
            rawName: "v-b-popover.hover.top",
            value: _vm.achDisabledDescription,
            expression: "achDisabledDescription",
            modifiers: { hover: true, top: true },
          },
        ],
        staticClass: "warning-icon",
        attrs: { icon: ["fas", "exclamation-triangle"] },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }