<template>
  <fa-icon
    v-if="showAchWarning"
    v-b-popover.hover.top="achDisabledDescription"
    class="warning-icon"
    :icon="['fas', 'exclamation-triangle']"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AchWarning',
  props: {
    cartItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      item: this.cartItem,
    }
  },
  computed: {
    ...mapGetters('website', ['website', 'isPurchaseCanaryWebsite']),
    ...mapGetters('checkout', ['affectedProductInCart']),
    achDisabledDescription() {
      return 'ACH cannot be used to purchase this product'
    },
    showAchWarning() {
      return this.isPurchaseCanaryWebsite ? this.item?.ach_disabled : false
    },
  },
}
</script>

<style lang="scss" scoped>
.warning-icon {
  padding-bottom: 0;
  color: #FFC109;
}
</style>
